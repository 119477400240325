<template>
  <div>
    <HeTong
      ref="hetong"
      v-if="showHt"
      :currentCode="currentCode"
      :currentItem="currentItem"
    />
    <el-dialog
      title="供应信息详情"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="160px">
        <el-form-item label="农服商名称:">
          {{ currentItem.companyName }} <span v-if="currentItem.city">({{ currentItem.city }})</span>
        </el-form-item>
        <el-form-item label="经营场所地址:">
          {{ currentItem.address }}
        </el-form-item>
        <el-form-item label="联系人:">
          {{ currentItem.contactMan&& currentItem.contactMan.substr(0,1)+(currentItem.sex==2? "女士" :"先生") }}
          <!-- {{ currentItem.contactMan }} -->
        </el-form-item>
        <el-form-item label="联系电话:">
          <template
            v-if="
              currentItem.phone && currentItem.phone.length >= 7
            "
          >
            {{
              currentItem.phone.slice(0, 3) +
              "****" +
              currentItem.phone.slice(-4)
            }}
          </template>
          <template v-else> 电话未定义 </template>
          <!-- {{
            currentItem.phone.slice(0, 3) + "****" + currentItem.phone.slice(-4)
          }} -->
        </el-form-item>
        <el-form-item label="意向区域:">{{
          currentItem.intentionRegion
        }}</el-form-item>
        <el-form-item label="作物类型:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="托管环节:">
          {{ currentItem.operScope }}
        </el-form-item>
        <el-form-item label="可接管土地面积:">
          {{ currentItem.serviceArea }} 亩</el-form-item
        >
        <el-form-item label="已接管土地面积:">
          {{ currentItem.serviceAreaOlde }} 亩</el-form-item
        >
        <el-form-item label="经营场所照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.companyPhoto"
            :key="index"
            :preview-src-list="currentItem.companyPhoto"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="其他说明:">{{ currentItem.remark }}</el-form-item>
      </el-form>
      <div class="btn" style="text-align: center">
        <el-button @click="back">关闭</el-button>
        <el-button @click="goout" type="primary">我要托管</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeTong from "./hetong.vue";
import { SendMessage } from "../../api/websocket";
export default {
  components: {
    HeTong,
  },
  data() {
    return {
      currentCode: 1,
      showHt: false,
      dialogVisible: true,
      baseUrl: window.globalUrl.HOME_API,
      currentItem: {},
      num:0
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {},
  methods: {
    async goout() {
      if (!this.identityObj.identityTypeCode) {
        this.$message.info("请登录后进行操作");
        return;
      }
      if (this.identityObj.identityTypeCode != 2 ) {
        this.$message.info("当前账户无权限，请切换农户角色后操作");
        return;
      }
      if (this.currentItem.authId == this.identityObj.identityId || this.currentItem.authId == this.num) {
        this.showHt = true;
        setTimeout(() => {
          this.$refs.hetong.selectOrder = "";
          this.$refs.hetong.getOrders(this.currentItem,this.num);
          // this.$refs.hetong.viewHt(this.currentItem, this.num);
        }, 100);
      } else {
        let params = {
          messageList: [
            {
              //消息类型会话托管
              messageType: 5,
            },
          ],
          receiverId: this.currentItem.authId,
          senderType: this.identityObj.identityTypeCode,
          //接受消息人类型只有公司认证用户
          receiverType: 4,
          senderId: this.identityObj.identityId,
          sender: this.identityObj.realName,
          receiver: this.currentItem.companyName,
        };
        let res = await SendMessage(params);
        this.$emit("endSearch")
        console.log("res", res);
        if (res.code !== 1) {
            let  params = {
              isshow: true, // 你要传递的参数
              data: this.currentItem,
              num:1,
            }
          this.$emit("closeDialog1", false);
          this.$emit("route", params);
        }
      }
    },

    init(obj, num) {
      this.num = num;
      console.log("init", obj);
      let xczp = [];
      if (obj.photos) {
        JSON.parse(obj.photos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }

      let companyPhoto = [];
      if (obj.companyPhoto == "" || obj.companyPhoto == null) {
        companyPhoto = [];
      } else if (typeof obj.companyPhoto === "string") {
        const arr = obj.companyPhoto.split(",");
        arr.forEach((item) => {
          companyPhoto.push(
            this.baseUrl + "admin/file/get?ossFilePath=" + item
          );
        });
      } else {
        companyPhoto = obj.companyPhoto;
      }
      obj.companyPhoto = companyPhoto;

      console.log(obj.photos, "");
      obj.xczp = xczp;
      this.currentItem = obj;
      console.log("88888", obj, num);
    },

    back() {
      this.$emit("closeDialog1", false);
    },
    handleClose() {
      this.$emit("closeDialog1", false);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog__body {
  padding-left: 30px;
}
/deep/.el-image {
  margin-right: 4px;
}
</style>
